.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 1.3em;
}
.grid-small-gaps {
  display: grid;
  flex-wrap: wrap;
  flex-basis: 5em;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 2px;
}

.width-1\/12 {
  grid-column: span 1;
}

.width-2\/12 {
  grid-column: span 2;
}

.width-3\/12 {
  grid-column: span 3;
}

.width-4\/12 {
  grid-column: span 4;
}

.width-5\/12 {
  grid-column: span 5;
}

.width-6\/12 {
  grid-column: span 6;
}

.width-7\/12 {
  grid-column: span 7;
}

.width-8\/12 {
  grid-column: span 8;
}

.width-9\/12 {
  grid-column: span 9;
}

.width-10\/12 {
  grid-column: span 10;
}

.width-11\/12 {
  grid-column: span 11;
}

.width-12\/12 {
  grid-column: span 12;
}

@media (min-width: 2em) {
  .width-1\/12\@m {
    grid-column: span 1;
  }
}

@media (min-width: 2em) {
  .width-2\/12\@m {
    grid-column: span 2;
  }
}

@media (min-width: 2em) {
  .width-3\/12\@m {
    grid-column: span 3;
  }
}

@media (min-width: 2em) {
  .width-4\/12\@m {
    grid-column: span 4;
  }
}

@media (min-width: 2em) {
  .width-5\/12\@m {
    grid-column: span 5;
  }
}

@media (min-width: 2em) {
  .width-6\/12\@m {
    grid-column: span 6;
  }
}

@media (min-width: 2em) {
  .width-7\/12\@m {
    grid-column: span 7;
  }
}

@media (min-width: 2em) {
  .width-8\/12\@m {
    grid-column: span 8;
  }
}

@media (min-width: 2em) {
  .width-9\/12\@m {
    grid-column: span 9;
  }
}

@media (min-width: 2em) {
  .width-10\/12\@m {
    grid-column: span 10;
  }
}

@media (min-width: 2em) {
  .width-11\/12\@m {
    grid-column: span 11;
  }
}

@media (min-width: 2em) {
  .width-12\/12\@m {
    grid-column: span 12;
  }
}

.grid__item {
  min-width: 1em;
  min-height: 1em;
  padding: 0.0em;
  margin: 0.0em;
  /* ------- customised: */
  text-align: center;
}
